/* Hide the progress bar */
.custom-video::-webkit-media-controls-timeline,
.custom-video::-webkit-media-controls-seek-bar {
  display: none !important;
}

.custom-video::-moz-range-track {
  display: none !important;
}

.custom-video::-ms-media-controls {
  display: none !important;
}
