.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  /* background: #ef4444; */
  background-image: url("./appLogo.png");
  /* background-size: cover; */
}

.site-layout .site-layout-background {
  background: #fff;
}

/* layout  */
.ant-layout-header {
  background-color: #fff !important;
}

.main-content {
  height: calc(100vh - 72px - 62px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ebebeb;
}

.main-container {
  margin-top: 2%;
}

/* home */
.patients-list {
  padding-left: 10px;
  padding-right: 10px;
}

.inline-display {
  display: inline-block;
}

/* antd */

.ant-layout-footer {
  text-align: center;
  background: #fff !important;
}
/* 
.ant-ribbon-text {
  background-color: #9ca3af !important;
} */

/* .ant-ribbon {
  background-color: #9ca3af !important;
} */

.ant-page-header {
  /* border-bottom: 1px solid #d2d7de !important; */
}

/* .ant-btn-link {
  color: #282c34 !important;
} */

/* Table */
.table-container {
  /* min-height: calc(70vh - 52px - 42px); */
  height: 70vh;
}

.table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table td,
.table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #ddd;
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #fff;
  color: rgb(0, 0, 0);
}

.card-shadow {
  box-shadow: 0 4px 8px 0 rgb(129 129 129 / 20%),
    0 6px 20px 0 rgb(122 122 122 / 19%);
}

.ant-table-tbody > tr > td h5 {
  font-size: 13px;
  font-weight: 600;
  color: #141414;
  margin-bottom: 0px;
}
.ant-table-tbody > tr > td p {
  font-size: 13px;
  color: #8c8c8c;
  margin: 0px;
}
/* .ant-table-tbody > tr > td {
  border: 1px solid rgba(0, 0, 0, 0.06);
} */
.ant-table-tbody > tr > td {
  padding: 2px, 8px !important;
  font-size: 13px;
}

.ant-table-thead > tr > th {
  color: #000000e0;
  font-weight: 600;
  background-color: #fafafa;
  font-size: 13px;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 16px 25px;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  white-space: nowrap;
}

.ant-table-tbody > tr > td h6 {
  font-size: 12px;
  font-weight: 600;
  color: #141414;
}

/* .ant-table-container {
  border: none !important;
} */

.TableScrollbar {
  height: 64vh !important;
}

.ant-image-preview-img {
  display: inline-block;
}
